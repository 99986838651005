var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('router-link', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "to": {
        name: 'trust-inheritance.heirs',
        query: {
          hibah_id: this.hibahId
        }
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/left-arrow.png",
      "height": "20",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "m-0",
    staticStyle: {
      "padding-left": "10px",
      "padding-top": "2px",
      "display": "inline-block"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back-to")) + " " + _vm._s(_vm.$t("heir-list")) + " ")])])], 1), _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2 mt-3"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("update-beneficiary-info")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Waris yang akan dihubungi jika berlaku kematian",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleInputEmail1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.designation,
      expression: "form.designation"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "designation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Encik"
    }
  }, [_vm._v("Encik")]), _c('option', {
    attrs: {
      "value": "Puan"
    }
  }, [_vm._v("Puan")]), _c('option', {
    attrs: {
      "value": "Dato"
    }
  }, [_vm._v("Dato")]), _c('option', {
    attrs: {
      "value": "Datin"
    }
  }, [_vm._v("Datin")])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "full_name",
      "required": ""
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.form.ic_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ic_number", $$v);
      },
      expression: "form.ic_number"
    }
  })], 1)]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    attrs: {
      "for": "old_ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.other_ic_number,
      expression: "form.other_ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "old_ic_number",
      "required": ""
    },
    domProps: {
      "value": _vm.form.other_ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "other_ic_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "relationship"
    }
  }, [_vm._v(_vm._s(_vm.$t("relationship")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.relationship,
      expression: "form.relationship",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control custom-select",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "relationship", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', [_vm._v("- " + _vm._s(_vm.$t("choose.relationship")) + " -")]), _c('option', {
    attrs: {
      "value": "Suami"
    }
  }, [_vm._v(_vm._s(_vm.$t("husband")))]), _c('option', {
    attrs: {
      "value": "Isteri"
    }
  }, [_vm._v(_vm._s(_vm.$t("wife")))]), _c('option', {
    attrs: {
      "value": "Anak"
    }
  }, [_vm._v(_vm._s(_vm.$t("children")))]), _c('option', {
    attrs: {
      "value": "Penjaga"
    }
  }, [_vm._v(_vm._s(_vm.$t("guardian")))]), _c('option', {
    attrs: {
      "value": "Adik-Beradik"
    }
  }, [_vm._v(_vm._s(_vm.$t("siblings")))]), _c('option', {
    attrs: {
      "value": "Bapa"
    }
  }, [_vm._v(_vm._s(_vm.$t("father")))]), _c('option', {
    attrs: {
      "value": "Ibu"
    }
  }, [_vm._v(_vm._s(_vm.$t("mother")))]), _c('option', {
    attrs: {
      "value": "Saudara"
    }
  }, [_vm._v(_vm._s(_vm.$t("relatives")))]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("others")))])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "home_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.home_phone_number,
      expression: "form.home_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "home_phone_number"
    },
    domProps: {
      "value": _vm.form.home_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "home_phone_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "mobile_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.mobile_number,
      expression: "form.mobile_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "mobile_number"
    },
    domProps: {
      "value": _vm.form.mobile_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "mobile_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "office_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.office_phone_number,
      expression: "form.office_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "office_number"
    },
    domProps: {
      "value": _vm.form.office_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "office_phone_number", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("mailing-address")) + " ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.address,
      expression: "form.address"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.form.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.form.postcode,
      expression: "form.postcode",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.form.postcode
    },
    on: {
      "blur": _vm.getPostcode,
      "change": function ($event) {
        return _vm.$set(_vm.form, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(_vm._s(_vm.$t("add-postcode")))])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control savePostcode",
    attrs: {
      "type": "number",
      "name": "userstate"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control saveCity",
    attrs: {
      "type": "text",
      "name": "userstate"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control saveState",
    attrs: {
      "type": "text",
      "name": "userstate"
    }
  })])]), _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "outline-danger",
      "block": ""
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }